export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Cosa c'è di nuovo?</p>
      <p className="new__description">
        Aggiornata la sezione "Bonus e promozioni"
      </p>
      <p className="new__description">
        Bonus aggiuntivi per i nuovi giocatori.
      </p>
      <p className="new__description">
        Risolto un problema con le notifiche push per alcuni utenti Android 10.
      </p>
      <p className="new__description">
        Abbiamo risolto un problema per cui il testo nelle Impostazioni era
        troppo piccolo per gli utenti Android.
      </p>
      {/* <p className="new__description">
        Biz Android foydalanuvchilari uchun Sozlamalardagi matn juda kichik
        bo‘lgan muammoni tuzatdik.
      </p> */}
    </div>
  );
};
